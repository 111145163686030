<template>
	<component
		:is="commonComponent"
		id="blog"
		title="Blogs">
		<section
			id="blog-content"
			class="pc_padding">
			<h1 class="m-b-30 head-1">"FactLine": Transformative Success Stories</h1>

      <div class="autor m-b-30">
        <div>Author: Momo </div>
        <div>Publish: 2024/10/16 </div>
      </div>
      <div class="slish m-b-20" />

      <AdComponent ref="ads-7" class="m-b-40 " :ads="adsensConfig.ad7"
        :showDebug="showDebug" />

      <p>
        In today's fast-paced, information-saturated world, where social media is omnipresent, many individuals find themselves feeling lost and anxious in their pursuit of success. However, "The Book of Answers" has served as a unique tool to guide countless individuals towards clarity. It is more than just a guide; it is a transformative tool for a lifestyle shift, enabling people to pursue happiness and achievement with a stronger sense of purpose. Below are some deeply moving success stories that showcase the profound influence of "The Book of Answers."
      </p>
      
      <myimg name="i1"></myimg>



      <h2 class="head-2 m-t-60 m-b-30">
        Overcoming Career Stagnation
      </h2>

	  <AdComponent ref="ads-8" class="m-b-40 " :ads="adsensConfig.ad8"
	  :showDebug="showDebug" />

      <p>
        Kelly, who had been navigating the advertising industry for years, possessed unique insights into creativity and market strategy. Yet her efforts seemed stagnant, not yielding the anticipated career advancement. Facing a professional bottleneck, Kelly often felt powerless. At a casual gathering, a friend recommended "The Book of Answers" to her. Initially skeptical, Kelly found her mindset changing after reading a few pages. The book's insights on self-reflection and career planning inspired her to reassess her abilities and goals.
      </p>

      <p>
        After weeks of dedicated reading and contemplation, Kelly identified her strengths in communication and creativity. Embracing the book’s suggestions, she decided to design a new project proposal and proactively recommend herself as the project leader. Through meticulous preparation and confident presentation, Kelly's proposal gained significant attention from management. Not only did she achieve a promotion to project manager, but she also led her team to achieve one success after another. This transformation made Kelly aware of her potential and opened new chapters in her career.
      </p>

      <h2>Rebuilding Social Connections</h2>
      <p>
        Social interaction was once a formidable barrier for Mark. Deep-seated insecurities and solitude left him uncomfortable in social settings, especially after relocating to an unfamiliar city. A friend casually mentioned "The Book of Answers," and Mark, with a "why not" attitude, delved into its chapters on social skills and building self-confidence.
      </p>
      <p>
        The book’s simple yet effective advice resonated with Mark. He began encouraging himself to initiate conversations, listen, and express himself in social gatherings. Mark even joined a local volunteer group, taking gradual steps to integrate into his new environment. Over time, he found himself enjoying social interactions and expanding his network.
      </p>
      <p>
        These newfound connections enriched his life and unexpectedly brought new professional opportunities. At a volunteer event, Mark met a seasoned industry professional who admired his dedication and enthusiasm, leading to a recommendation for an ideal job position. With the help of "The Book of Answers," Mark successfully rebuilt his life filled with happiness.
      </p>
      <h2>Achieving Mental Well-being</h2>
        <p>
          Emily had long been plagued by anxiety and stress, leaving her physically and mentally exhausted and robbing her of life’s joys. She sought a way to calm her spirit and improve mental clarity. Introduced to "The Book of Answers" by a friend, Emily discovered its practices in meditation and mindfulness opened a pathway to inner tranquility.
        </p>
        <p>
          In the following months, Emily consistently practiced the meditation techniques outlined in the book, gradually establishing her own routine of mindfulness. She found these simple yet profound exercises not only stabilized her emotions but also enhanced her focus and productivity. Emily began to emerge from the shadow of anxiety and rediscover life’s beauty.
        </p>
        <p>With her inner world reconstructed, she experienced career breakthroughs and advancement. Emily approached work challenges with newfound poise and positivity, earning recognition from supervisors and colleagues. She firmly believes that "The Book of Answers" played an indispensable role in her journey of inner growth.</p>
        <h2>
          Unleashing Inner Potential
        </h2>
        <p>
          Jack's passion for writing had never waned, but self-doubt had long hindered him from picking up the pen. Fear of failure and worrying about his works going unnoticed kept him from starting. One day, he stumbled upon "The Book of Answers," where chapters on creativity and self-exploration invigorated him.
        </p>
        <p>
          The book’s inspiring advice motivated him to start with the simplest of exercises, daily journaling. Jack set a daily writing plan and began honing his skills by chronicling life's moments and crafting short stories. Through consistent effort, his writing improved significantly, and more importantly, he regained confidence and passion for writing.
        </p>
        <p>
          Eventually, Jack decided to submit his works to various literary magazines. To his surprise, he received positive feedback from multiple publications, even securing opportunities for serializations. Thereafter, Jack transitioned to a career as a professional writer, realizing the life he had long dreamed of. As he reflects on this journey, he is filled with gratitude, acknowledging "The Book of Answers" for its unparalleled encouragement at a pivotal moment in his writing career.
        </p>
        <h2>Conclusion</h2>
        <p>
          "The Book of Answers" offers a clear and powerful guide, helping many find their path across various aspects of life. Whether in career development, social relationships, mental health, or personal growth, this book provides transformative guidance to those seeking change. These success stories demonstrate the immense influence of "The Book of Answers," proving that steadfast belief combined with the right guidance can create incredible life miracles. Through these stories, we witness how "The Book of Answers" supports people at different life stages, leading them toward a brighter future.
        </p>
      </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/common.scss';
import '@/css/blog_base.scss';

export default {
  name: 'Blog1',
  components: {
    AdComponent
  },
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: '"The Book of Answers": Transformative Success Stories',
      meta: [
        {
          name: 'description',
          content: 'In today\'s fast-paced, information-saturated world, where social media is omnipresent, many individuals find themselves feeling lost and anxious in their pursuit of success. However, "The Book of Answers" has served as a unique tool to guide countless individuals towards clarity. It is more than just a guide; it is a transformative tool for a lifestyle shift, enabling people to pursue happiness and achievement with a stronger sense of purpose. ',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig",

    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    //广告相关start
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.loadAdWithDelay()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    loadAdWithDelay() {
			setTimeout(() => {
				this.displayAd()
				this.setupIframeTracking()
			}, 500)
		},
		setupIframeTracking() {
			// let _this = this;
			console.log('ready to track iframe')
			const iframes = document.querySelectorAll('iframe')
			const tracker = new this.IframeTracker(this)
			iframes.forEach((iframe) => {
				tracker.addIframe(iframe, (iframeElement) => {
					console.log('Iframe 点击触发了!', iframeElement)
					const adContainer = iframeElement.closest('ins.adsbygoogle')

					if (adContainer) {
						// 获取父级 ins 元素的边界矩形
						const insRect = adContainer.getBoundingClientRect()

						// 计算父级 ins 元素相对于页面左上角的位置和高度
						const insX = insRect.left
						const insY = insRect.top
						const insHeight = insRect.height

						const windowWidth = window.innerWidth
						const windowHeight = window.innerHeight

						let transformData = {
							insX: insX,
							insY: insY,
							insHeight: insHeight,
							windowWidth: windowWidth,
							windowHeight: windowHeight,
						}
						this.$adClick(JSON.stringify(transformData))
						tracker.trackEvent('ad_iframe_click', 'click', {
							'data-ad-slot': adContainer.dataset.adSlot,
						})
					}
				})
			})
		},
		IframeTracker: class {
			constructor(vues, resolution = 200) {
				this.vues = vues // 保存 Vue 实例
				this.resolution = resolution
				this.iframes = []
				this.interval = null
				this.activeIframe = null // 存储最近被点击的iframe
				this.setupVisibilityTracking()
			}

			addIframe(element, callback) {
				const adContainer = element.closest('ins.adsbygoogle')
				const iframe = {
					element: element,
					callback: callback,
					hasTracked: false,
					adSlot: adContainer ? adContainer.dataset.adSlot : null,
				}
				this.iframes.push(iframe)

				if (!this.interval) {
					this.interval = setInterval(() => this.checkClick(), this.resolution)
				}
			}

			checkClick() {
				const activeElement = document.activeElement
				if (activeElement) {
					this.iframes.forEach((iframe) => {
						if (activeElement === iframe.element && !iframe.hasTracked) {
							iframe.callback(iframe.element)
							iframe.hasTracked = true
							this.activeIframe = iframe // 更新最近被点击的iframe
						}
					})
				}
			}

			setupVisibilityTracking() {
				const hiddenProperty = 'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null
				const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange')

				const onVisibilityChange = () => {
					if (!document[hiddenProperty]) {
						console.log('页面激活状态,返回页面?')
						this.activeIframe = null // 重置最近被点击的iframe记录
					} else {
						console.log('页面非激活状态')

						if (this.activeIframe) {
							// 上报逻辑
							this.trackEvent('ad_iframe_click_leave', 'click', {
								'data-ad-slot': this.activeIframe.adSlot,
							})
						}
					}
				}

				this.iframeVisibilityTracker = onVisibilityChange
				document.addEventListener(visibilityChangeEvent, this.iframeVisibilityTracker)
			}

			trackEvent(eventName, eventValue, dataAttributes) {
				// 事件追踪逻辑
				console.log(`${eventName} with value ${eventValue} on`, dataAttributes)

				this.vues.$eventrack(eventName, eventValue, {
					...dataAttributes,
				})
			}
		},
    async displayAd () {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    //广告相关end
  },
};
</script>
